import React from 'react';
import { Link } from 'react-router-dom';
import { Route } from '../Routes';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Copyright from './Copyright';

export default function MarComFooter() {
  return (
    <footer className="small-font">
      <Container fluid>
        <Row>
          <Col sm={3} xs={4}>
            <div>
              <Link to={Route.Security}>Security</Link>
            </div>
            <div>
              <Link to={Route.Privacy}>Privacy</Link>
            </div>
          </Col>
          <Col sm={3} xs={4}>
            <div>
              <Link to={Route.AboutUs}>About us</Link>
            </div>
            <div>
              <Link to={Route.Help}>Help</Link>
            </div>
          </Col>
          <Col sm={2} xs={4}>
            <div>
              <Link to="https://discord.gg/Fqz7j5HyPs" target="_blank">
                Discord
              </Link>
            </div>
            <div>
              <Link to="https://www.reddit.com/r/lopery" target="_blank">
                Reddit
              </Link>
            </div>
          </Col>
          <Col sm={4} xs={12} className="order-sm-first">
            <Link to={Route.Root}>
              <Image src="/logo.svg" style={{ filter: 'invert(1)', maxWidth: 140 }} width="100%" className="mt-4 mx-auto mt-sm-0" />
            </Link>
            <div className="mt-1 small-font">
              <Copyright />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
