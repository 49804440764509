import React from 'react';
import { Form } from 'react-bootstrap';

export default function Copyright() {
  const [copyrightClicks, setCopyRightClicks] = React.useState(0);
  const [consoleContent, setConsoleContent] = React.useState([]);

  // hijack the console log and error and append it to the consoleContent state
  React.useEffect(() => {
    const originalConsoleLog = console.log;
    const originalConsoleError = console.error;

    console.log = (...args: any[]) => {
      setConsoleContent((prev) => [...prev, `Log: ${JSON.stringify(args[0], null, 2)}`]);
      originalConsoleLog(...args);
    };

    console.error = (...args: any[]) => {
      setConsoleContent((prev) => [...prev, `Error: ${JSON.stringify(args[0], null, 2)}`]);
      originalConsoleError(...args);
    };
    return () => {
      console.log = originalConsoleLog;
    };
  });

  return (
    <>
      <span
        onClick={() => {
          setCopyRightClicks(copyrightClicks + 1);
        }}
      >
        ©️ Copyright Lopery {new Date().getFullYear()}
      </span>
      {copyrightClicks > 5 && (
        <Form.Control
          id="console"
          readOnly
          style={{
            minHeight: '15em',
            marginTop: '1em',
            fontSize: '0.8em',
          }}
          as="textarea"
          aria-label="console"
          value={consoleContent.join('\n')}
        />
      )}
    </>
  );
}
